body {
  color: #d6eaf7;
  background-color: #060e29;
  font-size: 3em;
}

a {
  text-decoration: none;
  color: #667ee8;
}

a:hover {
  color: #fff;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.voting-registration-question {
  max-width: 98%;
}

.lds-dual-ring {
  margin-top: 200px;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer {
  margin-top: 42px;
  font-size: 18px;
  color: #7c7e8a;
}




